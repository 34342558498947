import { RESPONSE_STATUS } from "constant/api/response/response_status.const";
import { CharacterImportResponse } from "common/character/dto/character_import.type";
import { characterImportResponseDefault } from "common/character/character_common";
import { axiosAsocijator } from "common/api/axios/asocijator_axios";
import {
  CharacterAddParams,
  CharacterCopyParams,
  CharacterImportParams,
  CharacterRelateContainUpdateParams,
  CharacterUpdateParams,
} from "./type/character_api.type";

export const characterAdd = async (
  characterData: CharacterAddParams
): Promise<string> => {
  let result: string = "";
  let response: any;
  try {
    response = await axiosAsocijator.post("character", characterData);
  } catch (error) {}
  if (response && response.status === RESPONSE_STATUS.RESOURCE_NEW) {
    result = response.data;
  } else if (
    response &&
    response.status === RESPONSE_STATUS.RESOURCE_ALREADY_EXISTS
  ) {
    result = "";
  }

  return result;
};

export const characterUpdate = async (
  id: string,
  updatePayload: CharacterUpdateParams
): Promise<string> => {
  let result: string = "";
  let response: any;
  try {
    response = await axiosAsocijator.put(`character/${id}`, updatePayload);
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};

export const characterDelete = async (id: string): Promise<boolean> => {
  let result: boolean = false;
  let response: any;
  try {
    response = await axiosAsocijator.delete(`character/${id}`);
    result = true;
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};

export const characterImport = async (
  characterData: CharacterImportParams
): Promise<CharacterImportResponse> => {
  let result: CharacterImportResponse = characterImportResponseDefault();
  let response: any;
  try {
    response = await axiosAsocijator.post("character/import", characterData);
  } catch (error) {}
  if (response && response.status === RESPONSE_STATUS.RESOURCE_NEW) {
    result = response.data;
  }

  return result;
};

export const characterCopy = async (
  copyData: CharacterCopyParams
): Promise<string> => {
  let result: string = "";
  let response: any;
  try {
    response = await axiosAsocijator.post("character/copy", copyData);
  } catch (error) {}
  if (response && response.status === RESPONSE_STATUS.OK) {
    result = response.data;
  } else {
    result = "";
  }

  return result;
};

export const characterRelateContainUpdate = async (
  id: string,
  updatePayload: CharacterRelateContainUpdateParams
): Promise<string> => {
  let result: string = "";
  let response: any;
  try {
    response = await axiosAsocijator.put(
      `character/${id}/contain`,
      updatePayload
    );
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};
