import styled from "styled-components";
import { TagResult } from "common/tag/dto/TagResult";
import TagSearchSelect from "../tag_util/TagSearchSelect";
import TagLiteList from "../TagList";
import { ItemTag } from "common/tag/dto/ItemTag";
import { useCallback, useEffect, useState } from "react";

type ItemTagsProps = {
  onTagsChange?: Function;
  resourceType: string;
  tagsDefault: ItemTag[];
  initialShowTagSelect?: boolean;
  channelId?: string;
};

const ItemTags = ({
  onTagsChange,
  resourceType,
  tagsDefault,
  initialShowTagSelect,
  channelId,
}: ItemTagsProps) => {
  const [tags, setTags] = useState<ItemTag[]>(
    tagsDefault ? [...tagsDefault] : []
  );
  const [showTagSelect, setShowTagSelect] = useState<boolean>(
    typeof initialShowTagSelect !== "undefined" ? initialShowTagSelect : true
  );

  useEffect(() => {
    if (JSON.stringify(tagsDefault) !== JSON.stringify(tags) && onTagsChange) {
      onTagsChange(tags);
    }
  }, [tags]);

  const tagSelectHandle = (tag: TagResult) => {
    if (
      !tags.find(
        (item: ItemTag) => item.title === tag.title && item.value === tag.value
      )
    ) {
      setTags([...tags, { title: tag.title, value: tag.value }]);
    }
  };

  const deleteClickHandle = (tag: ItemTag) => {
    const itemTags = tags.filter(
      (itemTag: ItemTag) =>
        itemTag.title !== tag.title || itemTag.value !== tag.value
    );
    setTags(itemTags);
  };

  const addTagClickHandle = () => {
    setShowTagSelect(true);
  };

  const cancelTagClickHandle = () => {
    setShowTagSelect(false);
  };

  return (
    <DivNav>
      {showTagSelect && (
        <TagSearchSelect
          resourceType={resourceType}
          onValueSelect={tagSelectHandle}
        />
      )}
      {!showTagSelect && (
        <DivAddTagNav onClick={addTagClickHandle}>add tag</DivAddTagNav>
      )}
      {showTagSelect && initialShowTagSelect === false && (
        <DivCancelAddTagNav onClick={cancelTagClickHandle}>
          cancel
        </DivCancelAddTagNav>
      )}
      <DivList>
        <TagLiteList
          tags={tags}
          deleteClickHandle={deleteClickHandle}
          channelId={channelId}
          resourceType={resourceType}
        />
      </DivList>
    </DivNav>
  );
};

const DivNav = styled.div`
  display: flex;
  margin-top: 0.75rem;
  width: 100%;
`;

const DivList = styled.div`
  display: flex;
  margin-left: 5%;
`;

const DivAddTagNav = styled.div`
  cursor: pointer;
`;

const DivCancelAddTagNav = styled.div`
  cursor: pointer;
  margin-left: 1rem;
  align-self: center;
`;

export default ItemTags;
