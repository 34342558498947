import { ReactRefI } from "type/common/ref.type";
import CharacterRelateGroupEdit from "../CharacterRelateGroupEdit";
import { CHARACTER_RELATE_CONTAIN_TAGS } from "./CharacterRelateContainEdit.const";

type CharacterRelateContainEditProps = {
  resultRef: ReactRefI<string[]>;
};

const CharacterRelateContainEdit = ({
  resultRef,
}: CharacterRelateContainEditProps) => {
  return (
    <CharacterRelateGroupEdit
      label="relate contain"
      initialValue={resultRef.current ?? []}
      resultRef={resultRef}
      tags={CHARACTER_RELATE_CONTAIN_TAGS}
    />
  );
};

export default CharacterRelateContainEdit;
