import SearchResultLiteList from "components/common/SearchResultLiteList";
import TagLiteItem from "./TagLiteItem";
import { ItemTag } from "common/tag/dto/ItemTag";

type TagLiteListProps = {
  tags: ItemTag[];
  clickHandle?: Function;
  selectedIds?: string[];
  deleteClickHandle?: Function;
  resourceType?: string;
  channelId?: string;
};

const TagLiteList = ({
  tags,
  clickHandle,
  deleteClickHandle,
  resourceType,
  channelId,
}: TagLiteListProps) => {
  console.log("TagLiteList render, resourceType", resourceType);

  return (
    <SearchResultLiteList<ItemTag>
      renderSingle={(tag: ItemTag, ind: number) => (
        <TagLiteItem
          tag={tag}
          key={`tag_lite_${ind}_${tag.title}_${tag.value}`}
          clickHandle={clickHandle}
          deleteClickHandle={deleteClickHandle}
        />
      )}
      results={tags}
    />
  );
};

export default TagLiteList;
