import { useMutation } from "@tanstack/react-query";
import { tagDelete } from "common/api/asocijator/asocijator_tag";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseTagDeleteParams = {
  invalidateKeys?: string[];
};

export const useTagDelete = (hookParams?: UseTagDeleteParams) => {
  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  const { mutate: tagDeleteMutate } = useMutation({
    mutationFn: async (id: string) => await tagDelete(id),
    onSuccess: () => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      }
    },
  });

  return { tagDeleteMutate };
};
