import { SelectFieldOption } from "components/UI/SelectField/SelectField.type";

export const TAG_SEARCH_SORT = {
  TITLE: {
    value: "title",
    label: "title",
  },
};

export const TAG_SORT_OPTIONS: SelectFieldOption[] = [
  {
    value: "",
    label: "podrazumevano",
  },
  {
    value: TAG_SEARCH_SORT.TITLE.value,
    label: TAG_SEARCH_SORT.TITLE.label,
  },
];

export const TAG_RESOURCE_TYPE = {
  ALL: "",
  TOPIC: "topic",
};

export const TAG_RESOURCE_TYPE_OPTIONS: SelectFieldOption[] = [
  {
    value: TAG_RESOURCE_TYPE.ALL,
    label: "svi",
  },
  {
    value: TAG_RESOURCE_TYPE.TOPIC,
    label: "topic",
  },
];
