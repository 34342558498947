import React, { useEffect } from "react";
import styled from "styled-components";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";
import { QUERY_KEYS } from "constant/react_query";
import { TagResult } from "common/tag/dto/TagResult";
import TagSingleNav from "components/common/tag/TagSingleNav/TagSingleNav";

type TagReadProps = {
  tag: TagResult;
};

const TagRead = ({ tag }: TagReadProps) => {
  const invalidateQuery = useInvalidateQueryByKeys();
  useEffect(() => {
    invalidateQuery.invalidateQueriesByKeys([QUERY_KEYS.ASOCIJATOR_TAG_GET]);
  }, [invalidateQuery]);

  return (
    <div>
      <div>{tag.title}</div>
      <DivNav>
        <TagSingleNav tag={tag} hideReadNav={true} />
      </DivNav>
    </div>
  );
};

const DivNav = styled.div`
  margin-top: 1.5rem;
`;

export default TagRead;
