import { TAG_RESOURCE_TYPE } from "constant/search/tag_search";
import { TagResult } from "./dto/TagResult";

export const tagFormDefaultValuesGet = (tag?: TagResult) => {
  if (tag) {
    return {
      title: tag.title,
      description: tag.description,
      resourceType: tag.resourceType,
      value: tag.value,
      uId: tag.uId,
    };
  } else {
    return {
      title: "",
      description: "",
      resourceType: TAG_RESOURCE_TYPE.TOPIC,
      value: "",
      uId: "",
    };
  }
};
