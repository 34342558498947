import React, { useRef } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { QUERY_KEYS } from "constant/react_query";
import { useNavigate } from "react-router-dom";
import { useTagAdd } from "hooks/asocijator/tag/useTagAdd";
import { tagFormDefaultValuesGet } from "common/tag/tag_form_common";
import TagForm from "components/common/tag/TagForm/TagForm";

const TagNew = () => {
  const { handleSubmit, reset, control } = useForm({
    defaultValues: tagFormDefaultValuesGet(),
  });
  const navigate = useNavigate();
  const { tagAddMutate } = useTagAdd({
    invalidateKeys: [QUERY_KEYS.ASOCIJATOR_TAG_SEARCH],
    successHandle: (id: any) => {
      console.log("add success", id);
      navigate(`/tag/${id}`);
    },
  });

  const parentIdRef = useRef<string>("");

  const onSubmit = async (data: any) => {
    const { title, value, resourceType, description } = data;

    tagAddMutate({
      title,
      value,
      resourceType,
      description,
    });
    toast.success("New tag added");
  };

  return (
    <>
      <div>Tag Add</div>
      <DivTag>
        <TagForm
          control={control}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          reset={reset}
        />
      </DivTag>
    </>
  );
};

const DivTag = styled.div`
  display: flex;
  flex-direction: column;
`;

export default TagNew;
