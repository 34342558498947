import { CharacterResult } from "common/character/dto/CharacterResult";
import { ItemTag } from "common/tag/dto/ItemTag";
import { CHARACTER_PER_PAGE_MAX } from "constant/character";

export const excludeIdsLettersFilter = (
  characters: CharacterResult[],
  excludeIds?: string[],
  excludeLetters?: string[]
): CharacterResult[] => {
  let result = [...characters];
  if (excludeIds && excludeIds.length > 0) {
    result = result.filter(
      (character: CharacterResult) => !excludeIds.includes(character.id)
    );
  }
  if (excludeLetters && excludeLetters.length > 0) {
    result = result.filter(
      (character: CharacterResult) => !excludeLetters.includes(character.letter)
    );
  }

  return result;
};

export const searchSelectResults = (
  characters: CharacterResult[],
  excludeIds?: string[],
  excludeLetters?: string[]
): CharacterResult[] => {
  let result = [...characters];
  if (excludeIds || excludeLetters) {
    result = excludeIdsLettersFilter(result, excludeIds, excludeLetters);
  }

  return result;
};

export const showSearchSelect = (
  characterSelection: CharacterResult
): boolean => {
  let result: boolean = false;
  if (characterSelection.id !== "") {
    result = true;
  }

  return result;
};

export const searchSelectInitial = (
  topicId?: string,
  tags?: ItemTag[]
): any => {
  const result: any = {
    limit: CHARACTER_PER_PAGE_MAX,
    skip: 0,
  };
  if (typeof topicId !== "undefined") {
    result.topicId = topicId;
  }
  if (typeof tags !== "undefined") {
    result.tags = tags;
  }

  return result;
};
