import styled from "styled-components";

export const DivCharacterRelateContainNav = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const DivNavType = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const RelateContainTypeNav = styled.div<{ active?: boolean }>`
  cursor: pointer;
  font-weight: ${(props) => (props.active ? "bold" : "auto")};
  text-decoration: ${(props) => (props.active ? "underline" : "auto")};
`;

export const DivCharacterNav = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const CharacterNav = styled.div<{ active?: boolean }>`
  cursor: pointer;
  font-weight: ${(props) => (props.active ? "bold" : "auto")};
  text-decoration: ${(props) => (props.active ? "underline" : "auto")};
`;
