import { useQuery } from "@tanstack/react-query";
import { characterSearch } from "common/api/asocijator/character/character_api";
import { CharacterSearchParams } from "common/api/asocijator/character/type/character_api.type";
import { QUERY_KEYS } from "constant/react_query";

type CharacterSearchOptions = {
  initialSearch: boolean;
};

const INITIAL_CHARACTER_SEARCH_OPTIONS: CharacterSearchOptions = {
  initialSearch: true,
};

export const useCharacterSearchQuery = (
  searchParams?: CharacterSearchParams,
  { initialSearch }: CharacterSearchOptions = INITIAL_CHARACTER_SEARCH_OPTIONS
): { data: any; refetch: Function } => {
  const { data, refetch } = useQuery({
    queryKey: [QUERY_KEYS.ASOCIJATOR_CHARACTER_SEARCH, searchParams],
    queryFn: async () => {
      const result = await characterSearch(searchParams);
      return result;
    },
    enabled: initialSearch,
  });

  return { data, refetch };
};

export const useCharacterSearch = (searchParams?: CharacterSearchParams) => {
  const { data: character } = useCharacterSearchQuery(searchParams);

  const result =
    typeof character !== "undefined" && character !== "" ? character : null;
  return result;
};

export const useCharacterSearchRefetch = (
  searchParams?: CharacterSearchParams
): { data: any; refetch: Function } =>
  useCharacterSearchQuery(searchParams, { initialSearch: false });
