import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useTagGet } from "hooks/asocijator/tag/useTagGet";
import TagEdit from "./TagEdit";
import TagRead from "./TagRead";

const TagComponent = () => {
  const { tagId } = useParams();
  const [searchParams] = useSearchParams();
  const [mode, setMode] = useState<string>("");
  const tag = useTagGet(tagId as string);
  useEffect(() => {
    const paramsMode = searchParams.get("mode") ?? "";
    if (paramsMode !== mode) {
      setMode(paramsMode);
    }
  }, [searchParams, tag, mode]);

  if (!tag) {
    return null;
  }

  return (
    <>
      {mode !== "edit" && <TagRead tag={tag} />}
      {mode === "edit" && <TagEdit tag={tag} />}
    </>
  );
};

export default TagComponent;
