import { ItemTag } from "common/tag/dto/ItemTag";
import { axiosAsocijator } from "../../axios/asocijator_axios";

export type TopicSearchParams = {
  skip?: number;
  limit?: number;
};

export type TopicAddParams = {
  name: string;
  description: string;
  tags: ItemTag[];
};

export const topicAdd = async ({
  name,
  description,
  tags,
}: TopicAddParams): Promise<string> => {
  let result: string = "";
  let response: any;
  try {
    response = await axiosAsocijator.post("topic", { name, description, tags });
  } catch (error) {}
  if (response && response.status === 201) {
    result = response.data;
  }

  return result;
};

const topicResponseFormat = (topic: any): any => {
  const result = { ...topic };

  return result;
};

export const topicGet = async (id: string): Promise<any> => {
  let result: any = null;
  let response: any;
  try {
    response = await axiosAsocijator.get(`topic/${id}`);
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};

export const topicSearch = async (
  searchParams?: TopicSearchParams
): Promise<any> => {
  let result: any = null;
  let response: any;
  try {
    response = await axiosAsocijator.post(`topic/search`, searchParams ?? {});
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
    result.items = result.items.map((item: any) => topicResponseFormat(item));
  }

  return result;
};

export const topicUpdate = async (
  id: string,
  updatePayload: any
): Promise<string> => {
  let result: string = "";
  let response: any;
  try {
    response = await axiosAsocijator.put(`topic/${id}`, updatePayload);
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};

export const topicDelete = async (id: string): Promise<boolean> => {
  let result: boolean = false;
  let response: any;
  try {
    response = await axiosAsocijator.delete(`topic/${id}`);
    result = true;
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};
