import { NavElements } from "type/navigation/main_navigation.type";

export const NAV_ELEMENTS: NavElements = {
  START: { title: "Start", path: "/" },
  TOPIC_NEW: { title: "Topic", path: "/topic/new" },
  TOPIC_LIST: { title: "Topic list", path: "/topic/list" },
  USER_NEW: { title: "User", path: "/user/new", adminAccess: true },
  USER_LIST: { title: "User list", path: "/user/list", adminAccess: true },
  USER_PROFILE: { title: "User profile", path: "/user-profile" },
  CHARACTER_NEW: { title: "Character", path: "/character/new" },
  CHARACTER_LIST: { title: "Character list", path: "/character/list" },
  CHARACTER_IMPORT_EXPORT: {
    title: "Character import and export",
    path: "/character/import-export",
  },
  ANSWER_PLAY: { title: "Answer play", path: "/answer-play" },
  UPUTSTVO: { title: "Uputstvo", path: "/uputstvo" },
  TAG_NEW: { title: "Tag", path: "/tag/new", adminAccess: true },
  TAG_LIST: { title: "Tag list", path: "/tag/list", adminAccess: true },
};

export const NAV_MODE: { [key: string]: string } = {
  READ: "read",
  EDIT: "edit",
  DEFAULT: "",
};
