import { useEffect } from "react";
import { CharacterResult } from "common/character/dto/CharacterResult";
import { CharacterNav } from "./CharacterRelateContainNav.style";
import { useCharacterByLetter } from "hooks/asocijator/character/useCharacterByLetter";

type CharacterRelateContainNavCharProps = {
  navCharacterLetter: string;
  navCharacterHandle: (navChar: CharacterResult | null) => void;
  navCharacterActive?: boolean;
};

const CharacterRelateContainNavChar = ({
  navCharacterLetter,
  navCharacterHandle,
  navCharacterActive,
}: CharacterRelateContainNavCharProps) => {
  const { character, refetch } = useCharacterByLetter(
    navCharacterLetter,
    false
  );

  const navCharacterClickHandle = () => {
    console.log(
      "navCharacterClickHandle - navCharacterActive, navCharacterLetter",
      navCharacterActive,
      navCharacterLetter
    );

    if (!navCharacterActive) {
      if (!character) {
        refetch();
      } else {
        navCharacterHandle(character);
      }
    } else {
      navCharacterHandle(null);
    }
  };

  useEffect(() => {
    if (character) {
      navCharacterHandle(character);
    }
  }, [character]);

  return (
    <CharacterNav onClick={navCharacterClickHandle} active={navCharacterActive}>
      {navCharacterLetter}
    </CharacterNav>
  );
};

export default CharacterRelateContainNavChar;
