import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useEffect, useRef, useState } from "react";
import { useDebounce } from "hooks/hook_util/debounce/useDebounce";
import { ReactRefI } from "type/ref.type";
import { useTagSearch } from "hooks/asocijator/tag/useTagSearch";
import { SEARCH_NAMES } from "constant/search/search";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";
import { QUERY_KEYS } from "constant/react_query";
import { TagResult } from "common/tag/dto/TagResult";
import { TagSearchParams } from "common/api/asocijator/asocijator_tag";
import { TAG_PER_PAGE_MAX } from "constant/tag.constants";
import { searchSelectResults } from "./TagSearchSelect.logic";

type TagSearchSelectProps = {
  excludeIds?: string[];
  onValueSelect?: Function;
  label?: string;
  resourceType?: string;
};

const getOptionLabel = (option: TagResult) => option.title + ":" + option.value;

const TagSearchSelect = ({
  excludeIds,
  onValueSelect,
  label,
  resourceType,
}: TagSearchSelectProps) => {
  const [value, setValue] = useState<TagResult | null>(null);
  const searchOptionsTimeRef: ReactRefI = useRef<null | number>(null);
  const [searchParams, setSearchParams] = useState<TagSearchParams>({
    limit: TAG_PER_PAGE_MAX,
    skip: 0,
    [SEARCH_NAMES.RESOURCE_TYPE]: resourceType,
  });
  const tagListResult = useTagSearch(searchParams);
  const handleTagSearch = useDebounce((searchText: string) => {
    console.log("debounced handleTagSearch, searchText", searchText);
    setSearchParams({
      ...searchParams,
      [SEARCH_NAMES.SEARCH_TEXT]: searchText,
      skip: 0,
    });
  }, 500);
  const invalidateQuery = useInvalidateQueryByKeys();
  useEffect(() => {
    const currentTime = new Date().getTime();

    if (
      searchOptionsTimeRef.current === null ||
      searchOptionsTimeRef.current < currentTime
    ) {
      invalidateQuery.invalidateQueriesByKeys([
        QUERY_KEYS.ASOCIJATOR_TAG_SEARCH,
      ]);
      searchOptionsTimeRef.current = currentTime;
    }
  }, [searchParams, invalidateQuery]);

  const results: TagResult[] = tagListResult
    ? searchSelectResults(tagListResult["items"], excludeIds)
    : [];

  const changeHandle = (event: any, newValue: TagResult | null) => {
    if (onValueSelect) {
      onValueSelect(newValue);
      setValue(null);
    }
  };

  const textChangeHandle = (event: any) => {
    handleTagSearch(event.target.value);
  };

  return (
    <Autocomplete
      getOptionLabel={getOptionLabel}
      value={value}
      id="combo-box-demo"
      options={results}
      blurOnSelect
      sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label ?? "Tag"}
          onChange={textChangeHandle}
        />
      )}
      onChange={changeHandle}
    />
  );
};

export default TagSearchSelect;
