import { useMutation } from "@tanstack/react-query";
import { tagUpdate } from "common/api/asocijator/asocijator_tag";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseTagUpdateParams = {
  invalidateKeys?: string[];
  invalidateKeysArray?: string[][];
};

export const useTagUpdate = (hookParams?: UseTagUpdateParams) => {
  const { invalidateQueriesByKeys, invalidateQueriesArraysByKeys } =
    useInvalidateQueryByKeys();
  const { mutate: tagUpdateMutate } = useMutation({
    mutationFn: async (params: any) =>
      await tagUpdate(params.id, params.updatePayload),
    onSuccess: () => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      } else if (hookParams?.invalidateKeysArray) {
        invalidateQueriesArraysByKeys(hookParams.invalidateKeysArray);
      }
    },
  });

  return { tagUpdateMutate };
};
