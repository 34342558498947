import React from "react";
import styled from "styled-components";

export type SearchResultLiteListProps<T> = {
  renderSingle: Function;
  results: T[];
  flexRow?: boolean;
};

function SearchResultLiteList<T>({
  renderSingle,
  results,
  flexRow,
}: SearchResultLiteListProps<T>) {
  return (
    <>
      {results.length > 0 && (
        <Div flexRow={flexRow}>
          {results.map((result: T, ind: number) => {
            return (
              <DivSingle key={`lite_l_i_${ind}`} flexRow={flexRow}>
                {renderSingle(result, ind)}
              </DivSingle>
            );
          })}
        </Div>
      )}
    </>
  );
}

const Div = styled.div<{ flexRow?: boolean }>`
  display: ${(props) => (props.flexRow ? "flex" : "auto")};
  flex-direction: ${(props) => (props.flexRow ? "row" : "auto")};
  div:first-of-type {
    margin-top: 0rem;
  }
  gap: ${(props) => (props.flexRow ? "1rem" : "auto")};
`;

const DivSingle = styled.div<{ flexRow?: boolean }>`
  margin-top: ${(props) => (props.flexRow ? "0rem" : "1rem")};
`;

export default SearchResultLiteList;
