import SearchResultList, {
  PageNavigation,
} from "components/common/SearchResultList";
import TagItem from "./TagItem";
import { useEffect, useState } from "react";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";
import { QUERY_KEYS } from "constant/react_query";
import { useTagSearch } from "hooks/asocijator/tag/useTagSearch";
import {
  tagSearchFieldsGet,
  tagResourceTypeDefaultGet,
} from "common/tag/tag_search";
import { useIsAdmin } from "hooks/auth/auth_current";
import { SEARCH_NAMES } from "constant/search/search";
import { TagSearchParams } from "common/api/asocijator/asocijator_tag";
import { TAG_PER_PAGE_MAX } from "constant/tag.constants";
import { TagResult } from "common/tag/dto/TagResult";
import TagSearchFilters from "components/common/tag/TagSearchFilters/TagSearchFilters";

const TagList = () => {
  const isAdmin: boolean = useIsAdmin();
  const [searchParams, setSearchParams] = useState<TagSearchParams>({
    limit: TAG_PER_PAGE_MAX,
    skip: 0,
    [SEARCH_NAMES.RESOURCE_TYPE]: tagResourceTypeDefaultGet(),
  });
  const tagListResult = useTagSearch(searchParams);
  const invalidateQuery = useInvalidateQueryByKeys();

  useEffect(() => {
    invalidateQuery.invalidateQueriesByKeys([QUERY_KEYS.ASOCIJATOR_TAG_SEARCH]);
  }, [searchParams, invalidateQuery]);

  let results: TagResult[] = tagListResult ? tagListResult["items"] : [];
  const resTotal: number = tagListResult ? tagListResult["resTotal"] : 0;
  const pageNavigationHandle = (pageNum: number) => {
    const skip = pageNum * TAG_PER_PAGE_MAX;
    setSearchParams({ ...searchParams, skip });
  };
  const pageNavigation: PageNavigation = {
    resTotal,
    limit: TAG_PER_PAGE_MAX,
    skip: searchParams.skip ?? 0,
    pageNavigationHandle,
  };
  const searchHandle = (formData: any) => {
    const searchVals = tagSearchFieldsGet(formData);

    setSearchParams({
      ...searchParams,
      ...searchVals,
      skip: 0,
    });
  };

  return (
    <div>
      <TagSearchFilters searchHandle={searchHandle} />
      <SearchResultList<TagResult>
        renderSingle={(tag: TagResult) => (
          <TagItem tag={tag} key={`tag_${tag.id}`} />
        )}
        results={results}
        pageNavigation={pageNavigation}
      />
    </div>
  );
};

export default TagList;
