import { CharacterRelateRefs } from "hooks/asocijator/character/useCharacterRelateRefs";
import { CharacterRelate } from "type/common/character/character.type";
import { CharacterImportResponse } from "./dto/character_import.type";

export const characterRelateDefaultGet = (): CharacterRelate => {
  return {
    meaning: [],
    pronounce: [],
    shape: [],
  };
};

export const characterRelateFromRefs = (
  refs: CharacterRelateRefs
): CharacterRelate => {
  return {
    meaning: refs.relateMeaningRef.current,
    pronounce: refs.relatePronounceRef.current,
    shape: refs.relateShapeRef.current,
    contain: refs.relateContainRef.current,
  };
};

export function characterImportResponseDefault(): CharacterImportResponse {
  return {
    imported: [],
    notImported: [],
    messages: [],
  };
}

export function sizeInfoGet(size: null | number): string {
  let result: string = "";
  if (size !== null) {
    if (size < 1000) {
      result = `(${size} KB)`;
    } else {
      let sizeMB: number = size / 1000;
      sizeMB = Math.round(sizeMB * 100) / 100;
      result = `(${sizeMB} MB)`;
    }
  }

  return result;
}
