import styled from "styled-components";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { TagResult } from "common/tag/dto/TagResult";
import { NavLink } from "react-router-dom";
import { useTagDelete } from "hooks/asocijator/tag/useTagDelete";
import { QUERY_KEYS } from "constant/react_query";
import { tagLinkSingle } from "common/tag/tag_link_common";
import useConfirm from "common/modal/ConfirmDialog";

type TagSingleNavProps = {
  tag: TagResult;
  hideEditNav?: boolean;
  hideReadNav?: boolean;
};

const TagSingleNav = ({ tag, hideEditNav, hideReadNav }: TagSingleNavProps) => {
  const { tagDeleteMutate } = useTagDelete({
    invalidateKeys: [QUERY_KEYS.ASOCIJATOR_TAG_SEARCH],
  });

  const actionConfirm = useConfirm();

  const deleteHandle = async () => {
    const check = await actionConfirm({
      title: "Tag",
      message: "Are you sure you want to delete tag",
    });
    if (check) {
      tagDeleteMutate(tag.id);
    }
  };

  return (
    <DivNav>
      <DeleteIcon onClick={deleteHandle} />
      {!hideEditNav && (
        <NavLink to={tagLinkSingle(tag.id, true)}>
          <EditIcon />
        </NavLink>
      )}
      {!hideReadNav && (
        <NavLink to={tagLinkSingle(tag.id)}>
          <ReadMoreIcon />
        </NavLink>
      )}
    </DivNav>
  );
};

const DivNav = styled.div`
  margin-top: 0.75rem;
  svg {
    margin-left: 1rem;
    cursor: pointer;
  }
  svg:hover {
    opacity: 0.8;
  }
  &&& > svg:first-of-type {
    margin-left: 0;
  }
`;

export default TagSingleNav;
