import { characterRelateFromRefs } from "common/character/character_common";
import { CharacterFile } from "common/character/dto/CharacterFile";
import { CharacterResult } from "common/character/dto/CharacterResult";
import { arrSimpleEqual } from "common/util/array/array_common";
import {
  pronounceConvert,
  pronounceConvertArr,
} from "common/util/convert/pronounce_convert";
import { CharacterRelateRefs } from "hooks/asocijator/character/useCharacterRelateRefs";
import { CharacterRelate } from "type/common/character/character.type";
import { ReactRefI } from "type/common/ref.type";

export function characterUpdatePayloadGet(
  formData: any,
  pronounceAddRef: ReactRefI<any[]>,
  examplesRef: ReactRefI<any[]>,
  characterRelateRefs: CharacterRelateRefs,
  fileEditRef: ReactRefI<any[]>,
  fileRemoveRef: ReactRefI<any[]>
): any {
  const { letter, meaning, pronounce } = formData;

  const pronounceAdd: string[] = pronounceAddRef.current;
  const examples: string[] = examplesRef.current;
  const relate: CharacterRelate = characterRelateFromRefs(characterRelateRefs);
  const characterFiles: CharacterFile[] =
    fileEditRef.current !== null ? fileEditRef.current : [];

  const updatePayload = {
    letter,
    meaning,
    pronounce: pronounceConvert(pronounce),
    pronounceAdd,
    examples: pronounceConvertArr(examples),
    file: characterFiles,
    fileRemove: fileRemoveRef.current,
    relate,
  };

  return updatePayload;
}

function charFilePathArrGet(charData: any): string[] {
  const res: string[] = [];
  for (let i = 0; i < charData.file.length; i++) {
    res.push(charData.file[i].path);
  }

  return res;
}

export function characterCheckUpdate(
  character: CharacterResult,
  payload: any
): boolean {
  let checkUpdate: boolean = false;

  if (typeof payload.pronounce !== "undefined") {
    if (
      // character.letter !== payload.leter ||
      character.pronounce !== payload.pronounce ||
      character.meaning !== payload.meaning ||
      !arrSimpleEqual(character.examples, payload.examples) ||
      !arrSimpleEqual(character.pronounceAdd, payload.pronounceAdd) ||
      !arrSimpleEqual(character.relate.meaning, payload.relate.meaning) ||
      !arrSimpleEqual(character.relate.pronounce, payload.relate.pronounce) ||
      !arrSimpleEqual(character.relate.shape, payload.relate.shape) ||
      !arrSimpleEqual(character.relate.contain, payload.relate.contain) ||
      !arrSimpleEqual(
        charFilePathArrGet(character),
        charFilePathArrGet(payload)
      ) ||
      payload.fileRemove.length > 0
    ) {
      checkUpdate = true;
    }
  }
  // console.log(
  //   "checkUpdate",
  //   checkUpdate,
  //   charFilePathArrGet(character),
  //   charFilePathArrGet(payload),
  //   arrSimpleEqual(charFilePathArrGet(character), charFilePathArrGet(payload)),
  //   payload.leter
  // );

  return checkUpdate;
}
