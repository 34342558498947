import { useEffect, useState } from "react";
import { CharacterResult } from "common/character/dto/CharacterResult";
import {
  DivCharacterNav,
  DivCharacterRelateContainNav,
} from "./CharacterRelateContainNav.style";
import CharacterRelateContainTypeNav from "./CharacterRelateContainTypeNav";
import CharacterRelateContainNavChar from "./CharacterRelateContainNavChar";

type CharacterRelateContainNavProps = {
  character: CharacterResult;
};

const CharacterRelateContainNav = ({
  character,
}: CharacterRelateContainNavProps) => {
  const [navCharacter, setNavCharacter] = useState<CharacterResult | null>(
    null
  );
  const [navLetterList, setNavLetterList] = useState<string[]>([]);

  const navCharacterHandle = (letters: string[]) => {
    setNavLetterList(letters);
    if (navCharacter !== null) {
      setNavCharacter(null);
    }
  };

  return (
    <DivCharacterRelateContainNav>
      <CharacterRelateContainTypeNav
        character={character}
        lettersGetHandle={navCharacterHandle}
      />
      {navLetterList && (
        <DivCharacterNav>
          {navLetterList.map((navCharacterLetter: string, ind: number) => (
            <CharacterRelateContainNavChar
              navCharacterLetter={navCharacterLetter}
              navCharacterHandle={setNavCharacter}
              navCharacterActive={navCharacter?.letter === navCharacterLetter}
              key={`nav_letter_${ind}`}
            />
          ))}
        </DivCharacterNav>
      )}
      {navCharacter !== null && (
        <CharacterRelateContainNav character={navCharacter} />
      )}
    </DivCharacterRelateContainNav>
  );
};

export default CharacterRelateContainNav;
