import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AsocijatorStart from "components/pages/AsocijatorStart";
import LoginPage from "components/pages/LoginPage";
import LogoutPage from "components/pages/LogoutPage";
import MainView from "./MainView";
import LoginView from "./LoginView";
import UserGuide from "components/pages/UserGuide";
import Asocijator from "components/pages/Asocijator";
import TopicList from "components/pages/topic/TopicList";
import TopicNew from "components/pages/topic/TopicNew";
import Topic from "components/pages/topic/Topic";
import UserList from "components/common/user/UserList";
import UserNew from "components/pages/user/UserNew";
import User from "components/pages/user/User";
import UserProfile from "components/pages/user/UserProfile";
import AsocijatorCommon from "./AsocijatorCommon";
import AnswerPlay from "components/pages/answer/AnswerPlay";
import CharacterList from "components/pages/character/CharacterList";
import CharacterNew from "components/pages/character/CharacterNew";
import Character from "components/pages/character/Character";
import AnswerPlayView from "./AnswerPlayView";
import CharacterImportExport from "components/pages/character/CharacterImportExport";
import { AddNavigationProvider } from "common/modal/AddNavigation";
import TagList from "components/pages/tag/TagList";
import TagNew from "components/pages/tag/TagNew";
import Tag from "components/pages/tag/Tag";

export default function AsocijatorMainContent() {
  return (
    <AsocijatorCommon>
      <Router>
        <AddNavigationProvider>
          <Routes>
            <Route element={<MainView />}>
              <Route path="/" element={<AsocijatorStart />} />
              <Route path="/topic">
                <Route path="list" element={<TopicList />} />
                <Route path="new" element={<TopicNew />} />
                <Route
                  path=":topicId/character/list"
                  element={<CharacterList />}
                />
                <Route path=":topicId" element={<Topic />} />
              </Route>
              <Route path="/character">
                <Route path="list" element={<CharacterList />} />
                <Route path="new" element={<CharacterNew />} />
                <Route
                  path="import-export"
                  element={<CharacterImportExport />}
                />
                <Route path=":characterId" element={<Character />} />
              </Route>
              <Route path="/user">
                <Route path="list" element={<UserList />} />
                <Route path="new" element={<UserNew />} />
                <Route path=":userId" element={<User />} />
              </Route>

              <Route path="/asocijator" element={<Asocijator />} />
              <Route path="/user-profile" element={<UserProfile />} />
              <Route path="/uputstvo" element={<UserGuide />} />
              <Route path="/tag">
                <Route path="list" element={<TagList />} />
                <Route path="new" element={<TagNew />} />
                <Route path=":tagId" element={<Tag />} />
              </Route>
            </Route>
            <Route element={<AnswerPlayView />}>
              <Route path="/answer-play" element={<AnswerPlay />} />
              <Route
                path="/topic/:topicId/answer-play"
                element={<AnswerPlay />}
              />
            </Route>
            <Route element={<LoginView />}>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/logout" element={<LogoutPage />} />
            </Route>
          </Routes>
        </AddNavigationProvider>
      </Router>
    </AsocijatorCommon>
  );
}
