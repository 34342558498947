import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { defaultValueGet } from "common/parameters_common";
import InputField from "components/UI/InputField";
import TagSelectResourceType from "../TagSelectResourceType";
import { TAG_RESOURCE_TYPE } from "constant/search/tag_search";

type TagFormProps = {
  control: any;
  handleSubmit: Function;
  onSubmit: Function;
  reset: Function;
  defaultValues?: any;
};

const TagForm = ({
  onSubmit,
  handleSubmit,
  reset,
  control,
  defaultValues,
}: TagFormProps) => {
  const titleDefault = defaultValueGet(defaultValues, "title", "");
  const valueDefault = defaultValueGet(defaultValues, "value", "");
  const resourceTypeDefault = defaultValueGet(
    defaultValues,
    "resourceType",
    TAG_RESOURCE_TYPE.TOPIC
  );
  const descriptionDefault = defaultValueGet(defaultValues, "description", "");

  return (
    <DivForm>
      <form>
        <DivField>
          <InputField
            name="title"
            control={control}
            label="title"
            defaultValue={titleDefault}
          />
        </DivField>
        <DivField>
          <InputField
            name="value"
            control={control}
            label="value"
            defaultValue={valueDefault}
          />
        </DivField>
        <DivField>
          <TagSelectResourceType
            control={control}
            fieldName="resourceType"
            defaultValue={resourceTypeDefault}
          />
        </DivField>

        <DivField>
          <InputField
            name="description"
            control={control}
            label="description"
            defaultValue={descriptionDefault}
          />
        </DivField>

        <DivNav>
          <Button onClick={handleSubmit(onSubmit)} type="submit">
            Submit
          </Button>
          <Button onClick={() => reset()} variant={"outlined"}>
            Reset
          </Button>
        </DivNav>
      </form>
    </DivForm>
  );
};

const DivForm = styled.div`
  margin-top: 0.75rem;
`;

const DivField = styled.div`
  margin-top: 1rem;
`;

const DivNav = styled.div`
  margin-top: 2rem;
`;

export default TagForm;
