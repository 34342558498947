import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTopicAdd } from "hooks/asocijator/topic/useTopicAdd";
import { QUERY_KEYS } from "constant/react_query";
import TopicForm from "components/common/topic/TopicForm/TopicForm";
import { useNavigate } from "react-router-dom";
import { topicFormDefaultValuesGet } from "common/topic/topic_form_common";
import { ItemTag } from "common/tag/dto/ItemTag";

const TopicNew = () => {
  const { handleSubmit, reset, control } = useForm({
    defaultValues: topicFormDefaultValuesGet(),
  });
  const navigate = useNavigate();
  const { topicAddMutate } = useTopicAdd({
    invalidateKeys: [QUERY_KEYS.ASOCIJATOR_TOPIC_SEARCH],
    successHandle: (id: any) => {
      console.log("add success", id);
      navigate(`/topic/${id}`);
    },
  });
  const onTagsChange = React.useCallback((itemTags: ItemTag[]) => {
    setTags(itemTags);
  }, []);
  const [tags, setTags] = React.useState<ItemTag[]>([]);

  const onSubmit = async (data: any) => {
    const { name, description } = data;

    const res = topicAddMutate({
      name,
      description,
      tags,
    });
    toast.success("New topic added");
  };

  return (
    <>
      <div>Topic Add</div>
      <DivTopic>
        <TopicForm
          control={control}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          reset={reset}
          onTagsChange={onTagsChange}
        />
      </DivTopic>
    </>
  );
};

const DivTopic = styled.div`
  display: flex;
  flex-direction: column;
  // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  // column-gap: 2rem;
  // row-gap: 2rem;
  // margin-top: 2rem;
`;

export default TopicNew;
