import { obDefaultValGet } from "common/common";
import { sortFieldsGet } from "common/search/search_common";
import { SEARCH_NAMES } from "constant/search/search";
import { TAG_RESOURCE_TYPE } from "constant/search/tag_search";
// import { TAG_RESOURCE_TYPE } from "constant/search/tag_search";

export const tagSearchFieldsGet = (searchFieldOb: any = null) => {
  let result = null;
  const resourceTypeDefault: string = tagResourceTypeDefaultGet();
  if (searchFieldOb === null) {
    result = {
      [SEARCH_NAMES.SEARCH_TEXT]: "",
      [SEARCH_NAMES.RESOURCE_TYPE]: resourceTypeDefault,
    };
  } else {
    result = {
      [SEARCH_NAMES.SEARCH_TEXT]: obDefaultValGet(
        searchFieldOb,
        SEARCH_NAMES.SEARCH_TEXT,
        ""
      ),
      [SEARCH_NAMES.RESOURCE_TYPE]: obDefaultValGet(
        searchFieldOb,
        SEARCH_NAMES.RESOURCE_TYPE,
        TAG_RESOURCE_TYPE.TOPIC
      ),
    };
  }

  const sortFields = sortFieldsGet(searchFieldOb);
  result = { ...result, ...sortFields };

  return result;
};

export function tagResourceTypeDefaultGet(): string {
  const tagResourceTypeDefault: string = TAG_RESOURCE_TYPE.TOPIC;

  return tagResourceTypeDefault;
}
