import { CharacterSearchParams } from "common/api/asocijator/character/type/character_api.type";
import { useCharacterSearchQuery } from "./useCharacterSearch";
import { CharacterResult } from "common/character/dto/CharacterResult";
import { CHARACTER_RELATE_CONTAIN } from "components/common/character/CharacterRelateContain/CharacterRelateContainNav/CharacterRelateContainNav.const";
import { SEARCH_NAMES } from "constant/search/search";
import { useEffect, useMemo, useState } from "react";

export const useCharacterContainTypeLetters = (
  character: CharacterResult | null,
  navType: string,
  initialSearch: boolean = true
): { letters: string[]; refetch: Function } => {
  const characterSearchParams: CharacterSearchParams = useMemo(() => {
    return {
      [SEARCH_NAMES.SEARCH_TYPE]: navType,
      [SEARCH_NAMES.CHARACTER_ID]: character?.id,
    };
  }, [navType, character?.id]);

  const { data: characterSearchResult, refetch } = useCharacterSearchQuery(
    characterSearchParams,
    { initialSearch }
  );
  const [letters, setLetters] = useState<string[]>([]);

  useEffect(() => {
    if (
      character &&
      characterSearchResult &&
      characterSearchResult.resTotal > 0
    ) {
      if (navType === CHARACTER_RELATE_CONTAIN.CONTAIN) {
        setLetters(character?.relate.contain);
      } else {
        const relateLetters: string[] = characterSearchResult.items.map(
          (characterResult: CharacterResult) => characterResult.letter
        );
        setLetters(relateLetters);
      }
    } else {
      setLetters([]);
    }
  }, [characterSearchResult]);

  return { letters, refetch };
};
