import { useMutation } from "@tanstack/react-query";
import { characterRelateContainUpdate } from "common/api/asocijator/character/character_edit_api";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";
import toast from "react-hot-toast";

type UseCharacterRelateContainUpdateParams = {
  invalidateKeys?: string[];
  invalidateKeysArray?: string[][];
};

type CharacterRelateContainUpdate = {
  id: string;
  updatePayload: any;
};

export const useCharacterRelateContainUpdate = (
  hookParams?: UseCharacterRelateContainUpdateParams
) => {
  const { invalidateQueriesByKeys, invalidateQueriesArraysByKeys } =
    useInvalidateQueryByKeys();
  const { mutate: characterRelateContainUpdateMutate } = useMutation({
    mutationFn: async (params: CharacterRelateContainUpdate) =>
      await characterRelateContainUpdate(params.id, params.updatePayload),
    onSuccess: () => {
      toast.success("Character relate contain updated");
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      } else if (hookParams?.invalidateKeysArray) {
        invalidateQueriesArraysByKeys(hookParams.invalidateKeysArray);
      }
    },
  });

  return { characterRelateContainUpdateMutate };
};
