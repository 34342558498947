import { ReactRefI } from "type/common/ref.type";
import { useRef } from "react";
import CharacterRelateContainEdit from "../../CharacterForm/CharacterRelateEdit/CharacterRelateContainEdit";
import styled from "styled-components";
import { Button } from "@mui/material";
import { useCharacterRelateContainUpdate } from "hooks/asocijator/character/useCharacterRelateContainUpdate";
import toast from "react-hot-toast";

type CharacterRelateContainEditNavProps = {
  characterId: string;
  contain: string[];
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const CharacterRelateContainEditNav = ({
  characterId,
  contain,
}: CharacterRelateContainEditNavProps) => {
  const relateContainRef: ReactRefI<string[]> = useRef<string[]>(contain);
  const { characterRelateContainUpdateMutate } =
    useCharacterRelateContainUpdate();

  const handleSave = () => {
    console.log("handleSave");
    const updatePayload = {
      id: characterId,
      contain: relateContainRef.current,
    };

    characterRelateContainUpdateMutate({
      id: characterId,
      updatePayload,
    });
  };

  return (
    <Div>
      <CharacterRelateContainEdit resultRef={relateContainRef} />
      <div>
        <Button onClick={handleSave} type="submit">
          Ok
        </Button>
      </div>
    </Div>
  );
};

export default CharacterRelateContainEditNav;
