import styled from "styled-components";
import NavLinkUndecorated from "components/common/style/link/NavLinkUndecorated";
import DeleteIcon from "@mui/icons-material/Close";
import { topicSearchTagLink } from "common/tag/tag_link_common";
import { ItemTag } from "common/tag/dto/ItemTag";

type TagLiteItemProps = {
  tag: ItemTag;
  clickHandle?: Function;
  deleteClickHandle?: Function;
};

const TagLiteItem = ({
  tag,
  clickHandle,
  deleteClickHandle,
}: TagLiteItemProps) => {
  const itemTagLink = topicSearchTagLink(tag);
  return (
    <Div>
      {!clickHandle && (
        <NavLinkUndecorated to={itemTagLink}>
          {tag.title}:{tag.value}
        </NavLinkUndecorated>
      )}
      {clickHandle && (
        <DivTag onClick={() => clickHandle(tag)}>
          {tag.title}:{tag.value}
        </DivTag>
      )}
      {deleteClickHandle && (
        <DivDelete>
          <DeleteIcon onClick={() => deleteClickHandle(tag)} fontSize="small" />
        </DivDelete>
      )}
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2rem;
`;

const DivTag = styled.div`
  cursor: pointer;
`;

const DivDelete = styled.div`
  margin-left: 1rem;
  display: flex;
  cursor: pointer;
`;

export default TagLiteItem;
