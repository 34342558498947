import { ItemTag } from "./dto/ItemTag";

export const tagLinkSingle = (
  tagId: string,
  isEditMode: boolean = false
): string => {
  let link = `/tag/${tagId}`;
  if (isEditMode) {
    link += "?mode=edit";
  }
  return link;
};

export const topicSearchTagLink = (itemTag: ItemTag): string => {
  const link = `/topic/list?tagTitle=${itemTag.title}&tagValue=${itemTag.value}`;

  return link;
};
