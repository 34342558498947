import { useMutation } from "@tanstack/react-query";
import { TagAddParams, tagAdd } from "common/api/asocijator/asocijator_tag";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseTagAddParams = {
  invalidateKeys?: string[];
  successHandle?: Function;
};

export const useTagAdd = (hookParams?: UseTagAddParams) => {
  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  const { mutate: tagAddMutate } = useMutation({
    mutationFn: async (tagAddParams: TagAddParams) =>
      await tagAdd(tagAddParams),
    onSuccess: (response) => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      }

      if (hookParams?.successHandle) {
        hookParams.successHandle(response);
      }
    },
  });

  return { tagAddMutate };
};
