import styled from "styled-components";
import { Divider } from "@mui/material";
import NavLinkUndecorated from "components/common/style/link/NavLinkUndecorated";
import { TagResult } from "common/tag/dto/TagResult";
import TagSingleNav from "components/common/tag/TagSingleNav/TagSingleNav";
import { tagLinkSingle } from "common/tag/tag_link_common";

type TagItemProps = {
  tag: TagResult;
};

const TagItem = ({ tag }: TagItemProps) => {
  return (
    <Div>
      <Divider />
      <Div>
        <NavLinkUndecorated to={tagLinkSingle(tag.id)}>
          <div>{tag.title}</div>
        </NavLinkUndecorated>
        <TagSingleNav tag={tag} />
      </Div>
    </Div>
  );
};

const Div = styled.div`
  margin-top: 2rem;
  line-height: 1.25rem;
`;

export default TagItem;
