import React, { useEffect } from "react";

function keyPressAct(ev: any) {
  // console.log("keyPressAct");
}

type KeyboardEventsProviderProps = { children: React.ReactNode };

export function KeyboardEventsProvider({
  children,
}: KeyboardEventsProviderProps) {
  useEffect(() => {
    window.addEventListener("keypress", keyPressAct);
    // console.log("KeyboardEventsProvider");
  }, []);

  return <>{children}</>;
}
