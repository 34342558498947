import { useCharacterSearchQuery } from "./useCharacterSearch";
import { CharacterResult } from "common/character/dto/CharacterResult";
import { SEARCH_NAMES } from "constant/search/search";

export const useCharacterByLetter = (
  letter: string,
  initialSearch: boolean = true
): { character: CharacterResult | null; refetch: Function } => {
  const { data: characterSearchResult, refetch } = useCharacterSearchQuery(
    { [SEARCH_NAMES.LETTER]: letter },
    { initialSearch }
  );
  let character: CharacterResult | null = null;

  if (characterSearchResult && characterSearchResult.resTotal > 0) {
    character = characterSearchResult.items[0];
    if (characterSearchResult.resTotal >= 2) {
      for (let i = 1; i < characterSearchResult.items.length; i++) {
        const resCharacter: CharacterResult = characterSearchResult.items[i];
        if (resCharacter.relate?.contain?.length > 0) {
          character = resCharacter;
        }
      }
    }
  }

  return { character, refetch };
};
