import { useQuery } from "@tanstack/react-query";
import {
  TagSearchParams,
  tagSearch,
} from "common/api/asocijator/asocijator_tag";
import { QUERY_KEYS } from "constant/react_query";

export const useTagSearch = (searchParams?: TagSearchParams) => {
  const { data: tag } = useQuery(
    [QUERY_KEYS.ASOCIJATOR_TAG_SEARCH, searchParams],
    async () => {
      const result = await tagSearch(searchParams);
      return result;
    },
    { staleTime: Infinity }
  );
  const result = typeof tag !== "undefined" && tag !== "" ? tag : null;
  return result;
};
