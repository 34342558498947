import { axiosAsocijator } from "../axios/asocijator_axios";

export type TagSearchParams = {
  skip?: number;
  limit?: number;
};

export type TagAddParams = {
  title: string;
  value: string;
  resourceType: string;
  description: string;
};

export const tagAdd = async ({
  title,
  value,
  resourceType,
  description,
}: TagAddParams): Promise<string> => {
  let result: string = "";
  let response: any;
  try {
    response = await axiosAsocijator.post("tag", {
      title,
      value,
      resourceType,
      description,
    });
  } catch (error) {}
  if (response && response.status === 201) {
    result = response.data;
  }

  return result;
};

const tagResponseFormat = (tag: any): any => {
  const result = { ...tag };

  return result;
};

export const tagGet = async (id: string): Promise<any> => {
  let result: any = null;
  let response: any;
  try {
    response = await axiosAsocijator.get(`tag/${id}`);
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};

export const tagSearch = async (
  searchParams?: TagSearchParams
): Promise<any> => {
  let result: any = null;
  let response: any;
  try {
    response = await axiosAsocijator.post(`tag/search`, searchParams ?? {});
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
    result.items = result.items.map((item: any) => tagResponseFormat(item));
  }

  return result;
};

export const tagUpdate = async (
  id: string,
  updatePayload: any
): Promise<string> => {
  let result: string = "";
  let response: any;
  try {
    response = await axiosAsocijator.put(`tag/${id}`, updatePayload);
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};

export const tagDelete = async (id: string): Promise<boolean> => {
  let result: boolean = false;
  let response: any;
  try {
    response = await axiosAsocijator.delete(`tag/${id}`);
    result = true;
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};
