import { TagResult } from "common/tag/dto/TagResult";

export const excludeIdsFilter = (
  tags: TagResult[],
  excludeIds?: string[]
): TagResult[] => {
  let result = [...tags];
  if (excludeIds && excludeIds.length > 0) {
    result = result.filter((tag: TagResult) => !excludeIds.includes(tag.id));
  }

  return result;
};

export const searchSelectResults = (
  tags: TagResult[],
  excludeIds?: string[]
): TagResult[] => {
  let result = [...tags];
  if (excludeIds) {
    result = excludeIdsFilter(result, excludeIds);
  }

  return result;
};
