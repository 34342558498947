import React from "react";
import styled from "styled-components";
import CharacterSingleNav from "components/common/character/CharacterSingleNav/CharacterSingleNav";
import { CharacterResult } from "common/character/dto/CharacterResult";
import { DivMarginTop } from "components/common/style/margin/DivMarginTop";
import CharacterRelateContainNav from "components/common/character/CharacterRelateContain/CharacterRelateContainNav";

type CharacterReadProps = {
  character: CharacterResult;
};

const CharacterRead = ({ character }: CharacterReadProps) => {
  return (
    <div>
      <div>{character.letter}</div>
      <DivNav>
        <CharacterSingleNav character={character} hideReadNav={true} />
        <DivMarginTop mt={1}>
          <CharacterRelateContainNav character={character} />
        </DivMarginTop>
      </DivNav>
    </div>
  );
};

const DivNav = styled.div`
  margin-top: 1.5rem;
`;

export default CharacterRead;
