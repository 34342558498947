import React, { useRef } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { QUERY_KEYS } from "constant/react_query";
import { useTagUpdate } from "hooks/asocijator/tag/useTagUpdate";
import { tagFormDefaultValuesGet } from "common/tag/tag_form_common";
import { TagResult } from "common/tag/dto/TagResult";
import TagForm from "components/common/tag/TagForm/TagForm";
import TagSingleNav from "components/common/tag/TagSingleNav/TagSingleNav";

type TagEditProps = {
  tag: TagResult;
};

const TagEdit = ({ tag }: TagEditProps) => {
  const { handleSubmit, reset, control } = useForm({
    defaultValues: tagFormDefaultValuesGet(tag),
  });
  const { tagUpdateMutate } = useTagUpdate({
    invalidateKeysArray: [
      [QUERY_KEYS.ASOCIJATOR_TAG_SEARCH],
      [QUERY_KEYS.ASOCIJATOR_TAG_GET],
    ],
  });

  const onSubmit = async (data: any) => {
    // console.log(data);

    const { title, value, resourceType, description } = data;
    tagUpdateMutate({
      id: tag.id,
      updatePayload: {
        title,
        value,
        resourceType,
        description,
      },
    });
    toast.success("Tag updated");
  };

  return (
    <DivTag>
      <TagForm
        control={control}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        reset={reset}
        defaultValues={tagFormDefaultValuesGet(tag)}
      />

      <DivNav>
        <TagSingleNav tag={tag} hideEditNav={true} />
      </DivNav>
    </DivTag>
  );
};

const DivTag = styled.div`
  display: flex;
  flex-direction: column;
`;

const DivNav = styled.div`
  margin-top: 1rem;
`;

export default TagEdit;
