import React, { useEffect, useState } from "react";
import { CHARACTER_RELATE_CONTAIN } from "./CharacterRelateContainNav.const";
import {
  DivNavType,
  RelateContainTypeNav,
} from "./CharacterRelateContainNav.style";
import { CharacterResult } from "common/character/dto/CharacterResult";
import { useCharacterContainTypeLetters } from "hooks/asocijator/character/useCharacterContainTypeLetters";

type CharacterRelateContainTypeNavProps = {
  character: CharacterResult;
  lettersGetHandle: (letters: string[]) => void;
};

const CharacterRelateContainTypeNav = ({
  character,
  lettersGetHandle,
}: CharacterRelateContainTypeNavProps) => {
  const [navType, setNavType] = useState<string>(
    CHARACTER_RELATE_CONTAIN.CONTAIN
  );
  const { letters: containTypeLetters, refetch: refetchContainTypeLetters } =
    useCharacterContainTypeLetters(character, navType, false);

  useEffect(() => {
    if (navType !== "") {
      if (navType === CHARACTER_RELATE_CONTAIN.CONTAIN) {
        lettersGetHandle(character.relate.contain);
      } else {
        refetchContainTypeLetters();
      }
    } else {
      lettersGetHandle([]);
    }
  }, [navType]);

  useEffect(() => {
    if (navType !== CHARACTER_RELATE_CONTAIN.CONTAIN) {
      lettersGetHandle(containTypeLetters);
    }
  }, [containTypeLetters]);

  const navTypeClickHandle = (navigationType: string) => {
    if (navigationType !== navType) {
      setNavType(navigationType);
    } else {
      setNavType("");
    }
  };

  return (
    <DivNavType>
      <RelateContainTypeNav
        active={navType === CHARACTER_RELATE_CONTAIN.CONTAIN}
        onClick={() => navTypeClickHandle(CHARACTER_RELATE_CONTAIN.CONTAIN)}
      >
        &or;
      </RelateContainTypeNav>
      <RelateContainTypeNav
        active={navType === CHARACTER_RELATE_CONTAIN.PART}
        onClick={() => navTypeClickHandle(CHARACTER_RELATE_CONTAIN.PART)}
      >
        &and;
      </RelateContainTypeNav>
      <RelateContainTypeNav
        active={navType === CHARACTER_RELATE_CONTAIN.SHARE}
        onClick={() => navTypeClickHandle(CHARACTER_RELATE_CONTAIN.SHARE)}
      >
        &lt;
      </RelateContainTypeNav>
      <RelateContainTypeNav
        active={navType === CHARACTER_RELATE_CONTAIN.BUILD_WORD_EXPRESSION}
        onClick={() =>
          navTypeClickHandle(CHARACTER_RELATE_CONTAIN.BUILD_WORD_EXPRESSION)
        }
      >
        &gt;
      </RelateContainTypeNav>
    </DivNavType>
  );
};

export default CharacterRelateContainTypeNav;
